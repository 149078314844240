const axios = require('axios').default;
import { gsap } from "gsap";
import { isMobile } from 'mobile-device-detect';

import Wine from './modules/wine';

class Router {
	constructor() {
		// console.log('hello router');
	}

	preloadedPages = []; //todo convert to Map

	initLinks() {
		// console.log('initLinks');
		return new Promise((resolve, reject) => {
			try {
				let links = [
					...document.querySelectorAll(
						'body a:not([target="_blank"]):not([href*="#"]):not([href*="mailto:"]):not([href*="tel:"]):not(.ab-item):not(.fetch-link):not([href*="wp-"]):not(.lang-link)'
					),
				];

				for (const link of links) {
                    link.classList.add('fetch-link');

					let href = link.getAttribute('href');

					if (href.indexOf(window.location.hostname) < 0) {
						link.setAttribute('target', '_blank');
						continue;
					}

                    if(!isMobile) {
                        link.addEventListener('mouseenter', this.fetchPage.bind(this, href));
                    }
					

					link.addEventListener('click', (e) => {
						e.preventDefault();
						this.changeRoute(href);
					});
				}

				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	fetchPage = (href) => {

        return new Promise((resolve,reject) => {
            if (this.preloadedPages[href]) {
                resolve();
                return;
            }
                

            axios
                .get(href)
                .then((res) => {
                    // console.log(res.data);
                    this.preloadedPages[href] = {
                        content: res.data,
                    };

                    // todo check if page is wine and preload image

                    if(res.data.includes('data-object="wine"')) {
                        // console.log('page is wine!');
                        let wine = Wine._preload(res.data);
                        // console.log(wine);
                    }

                    resolve();
                })
                .catch((err) => {
                    // console.error(err);
                    if (err.status === 400) {
                        this.preloadedPages[href] = {
                            content: res.data,
                        };
                        resolve();
                    }

                    reject(err);
                });

        });
	};

	async changeRoute(href) {

        if(!this.preloadedPages[href]) {
            // console.log('fetchpage in mount method');
            //non ho precaricatop la pagina oppure il server non ha ancora risposto alla chiamata precedente
            try {
                
                await this.fetchPage(href);
                // console.log('await fetchPage');
            } catch (error) {
                // errore nella chiamata alla pagina.
                return;
            }
        }

        await window.App.Page.mount(this.preloadedPages[href].content);

		// UPDATE HISTORY
		this.updateHistory(href);

		// GOOGLE ANALYTICS
		if (typeof ga !== 'undefined') {
			ga('send', 'pageview', {
				page: document.location.href,
				title: document.title,
			});
		}

		// INIT NEW INTERNAL LINKS
		// this.initLinks();
        
        if(window.App?.Mouse) {
            window.App.Mouse.initLinks();
        }
	};

    updateHistory(href) {
        if (typeof window.history !== 'undefined') {
			if (href !== window.location.href) {
				// window.blockhashchange = true;
				window.history.pushState(null, '', href);
			}
		}
    }

    pageOutAnimation() {
        return new Promise((resolve) => {

        
            if(window.App && window.App.MainMenu) {
                window.App.MainMenu.close();

                gsap.to('#page',{duration: 1, opacity: 0, onComplete: () => {
                    resolve();
                }});
            } else {
                resolve();
            }

        });
        

    }
    
}

export default new Router();
