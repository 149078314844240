import { gsap } from "gsap";
import paper from 'paper';
import { isMobile } from 'mobile-device-detect';

/* -----------------------------
    MOUSE CLASS
------------------------------*/

export default class Mouse {

    isNoisy = false;
    clientX = 0;
    clientY = 0;
    group = null;
    cursorStart = false;

    constructor() {

        if(isMobile)
            return 


        this.cursorPoint = new paper.Point(0,0);

        this.initLinks();
        
        //initialize cursor only on desktop device 
        this.initPointer().then(() => {
            return this.initCanvas();
        }).then(() => {

            document.addEventListener('mousemove',this.onMousemove);

            setTimeout( () => {

                // gsap.fromTo(this.cursorInner,{opacity: 0},{duration: 2, opacity: 1, onStart: () => {
                    
                    // console.log({...this.group.position});
                    // if(this.group && this.group.position) 
                    //     gsap.set(this.group.position,{x: this.clientX, y: this.clientY});
                    // this.render();
                    window.requestAnimationFrame(this.render);
    
                    window.onbeforeunload = this._beforeUnload;
                // }});

            },0);  

            // window.requestAnimationFrame(this.render);
            
        });        
    }

    initPointer = () => {
        return new Promise((resolve,reject) => {
            try {
                this.cursorInner = document.getElementById('cursor--inner');
                this.cursorContent = document.getElementById('cursor--content');

                this.plus = document.getElementById('cursor-plus');
                this.close = document.getElementById('cursor-close');
                this.text = document.getElementById('cursor-text');

                this.clearCursorContent();

                this.clientX = 0;
                this.clientY = 0;
                // console.log('init cursor inner',this.cursorInner);
                resolve();
                
            } catch (error) {
                reject(error);
            }
        });
        
    }

    initLinks = () => {
        // console.log('Mouise init links');
        const linkItems = document.querySelectorAll("a, .cursor-pointer");
        // linkItems.forEach(item => {
        //     item.addEventListener("mouseenter", this.onMouseenter);
        //     item.addEventListener("mouseleave", this.onMouseleave);
        // });
        this.addLinks(...linkItems);
    }

    addLinks = (...linkItems) => {
        if(isMobile) return;

        linkItems.forEach(item => {
            item.addEventListener("mouseenter", this.onMouseenter.bind(this,item));
            item.addEventListener("mouseleave", this.onMouseleave.bind(this,item));
            item.addEventListener("click",this.fadeOutContent.bind(this,0.5,item));
        });
    }

    initCanvas = () => {

        return new Promise((resolve,reject) => {

            try {

                // console.log('init canvas');

                this.canvas = document.getElementById('cursor--outer');
                paper.setup(this.canvas);

                // console.log(new paper.Point(0, 0));

                this.strokeColor = "rgba(255, 255, 255, 1)";
                this.strokeColorHover = "rgba(11,68,25,1)";
                this.strokeWidth = 1;
                
                this.fillColor = "rgba(255, 255, 255, 0.0)";
            
                const segments = 8;
                const radius = 15;
                // const noiseScale = 150; // speed
                // const noiseRange = 4; // range of distortion
                // let isNoisy = false;
            
                this.polygon = new paper.Path.RegularPolygon(
                new paper.Point(0,0),
                segments,
                radius
                );

                this.polygon.strokeColor = this.strokeColor;
                this.polygon.fillColor = this.fillColor;
                this.polygon.strokeWidth = this.strokeWidth;
                this.polygon.smooth();

                
            
                this.group = new paper.Group([this.polygon]);
                this.group.applyMatrix = false;

                this.group.position.x = 0;
                this.group.position.y = 0;

                
                // this.group.onFrame = (event) => {
                //     // Each frame, move the path 1/30th of the difference in position
                //     // between it and the destination.
                //     // console.log(event);
                //     // The vector is the difference between the position of
                //     // the text item and the destination point:
                //     var vector = this.cursorPoint - this.polygon.position;
                    
                //     // We add 1/30th of the vector to the position property
                //     // of the text item, to move it in the direction of the
                //     // destination point:
                //     // if(vector.length > 0.01)
                //     this.polygon.position += vector / 10;
                    
                    
                // }

                resolve();
                
            } catch (error) {
                reject(error);
            }

        });
        
    }

    startCursor = function() {

        gsap.set(this.cursorInner, {x: this.clientX, y: this.clientY});
        gsap.set(this.cursorContent, {x: this.clientX, y: this.clientY});
        // gsap.set(this.group.position,{x: this.clientX, y: this.clientY});
        gsap.set(this.group.position,{x: this.clientX, y: this.clientY});
       
        gsap.to(this.canvas,{duration: 3, opacity: 1});
        gsap.to(this.cursorInner,{duration: 3, opacity: 1});

        
        this.cursorStart = !this.cursorStart;

    }


    onMousemove = (e) => {
        // console.log(e);
        !this.cursorStart ? this.startCursor() : null;
        this.clientX = e.clientX;
        this.clientY = e.clientY;
        // this.cursorPoint = new paper.Point(e.clientX,e.clientY);
        // console.log(this.cursorPoint,this.polygon?.position);
        
    }

    onMouseenter = (el,e) => {
        // console.log('mouse enter');
        
        this.cursorInner && gsap.killTweensOf(this.cursorInner);
        // gsap.to(this.polygon,{duration: 0.5, strokeColor: this.strokeColorHover});
        this.cursorInner && gsap.to(this.cursorInner,{duration: 0.5, opacity: 0, scale: 0});
        
        // console.log(el);
        this.toggleCursor(el);
        
    }

    resetCursor = () => {

        return new Promise((resolve,reject) => {

            try {

                this.cursorScale(15);
                gsap.set(this.canvas,{'mix-blend-mode': 'difference'});
                gsap.set(this.polygon,{fillColor: 'rgba(255,255,255,0)',strokeColor: 'rgba(255, 255, 255, 1)'});

                this.fadeOutContent(0.5).then(() => {
                    resolve();
                });
                // gsap.to([this.plus,this.close,this.text],{duration: 0.3, opacity: 0, onComplete: () => {
                //     // this.cursorContent.innerHTML = '';
                //     resolve();
                // }});
                
                
            } catch (error) {
                reject(error);
            }
        });

    }

    clearCursorContent = () => {
        this.plus.remove();
        this.close.remove();
        this.text.remove();
    }

    fadeOutContent = (duration = 0.3,item = null) => {
        return new Promise((resolve,reject) => {
            try {

                if(item) {
                    item.removeEventListener("mouseenter", this.onMouseenter.bind(this,item));
                }
                
                gsap.to([this.plus,this.close,this.text],{duration: duration, opacity: 0, onComplete: () => {
                    // this.cursorContent.innerHTML = '';


                    if(item) {
                        item.addEventListener("mouseenter", this.onMouseenter.bind(this,item));
                    }
                    

                    resolve();
                }});

            } catch (error) {
                reject(error);
            }
        });
    }

    toggleCursor = (el,action = true) => {

        if(isMobile) {
            return;
        }

        let { classList } = el;
        let duration = 0.5;
        this.clearCursorContent();

        if(classList.contains('gallery__item')) {
            // open gallery button vs close gallery button
            if ( action ) {

                // if(this.cursorContent.children.length > 0) {
                //     this.clearCursorContent();
                // }

                this.cursorContent.appendChild(this.close);
                gsap.to(this.close,{duration: duration, opacity: 1, delay: 0.3});
                this.cursorScale(38);
                return;
            }

            this.resetCursor();
            
            // shoe close icon

        } else if(classList.contains('gallery-trigger')) {

            if( action ) {

                // if(this.cursorContent.children.length > 0) {
                //     this.clearCursorContent();
                // }

                this.cursorContent.appendChild(this.plus);
                gsap.to(this.plus,{duration: duration, opacity: 1, delay: 0.3});
                this.cursorScale(38);

                return;
            }
            
            this.resetCursor();
            // show plus icon
        } else if (  classList.contains('wine-container__link') ) {

            if(action) {

                // if(this.cursorContent.children.length > 0) {
                //     this.clearCursorContent();
                // }

                this.cursorContent.appendChild(this.text);
                gsap.to(this.text,{duration: duration, delay: 0.2, opacity: 1});
                this.cursorScale(55);
                gsap.set(this.canvas,{'mix-blend-mode': 'initial'});
                gsap.set(this.polygon,{fillColor: 'rgba(255,255,255,1)', strokeColor: 'rgba(255,255,255,1)'});
               

                return;
            }

            this.resetCursor();
            
            
            // this.
            // change polygon background color and border color
            // show inner text

        } else {
            // this.isNoisy = true;
            // this.cursorNoise();
            action ? this.cursorScale(38,this.noising) : this.resetCursor();
        }
    }

    onMouseleave = (el,e) => {
        // console.log('mouse leave');
        // this.isNoisy = false;
        // gsap.to(this.polygon,{duration: 0.5, strokeColor: this.strokeColor});
        this.cursorInner && gsap.to(this.cursorInner,{duration: 0.8, opacity: 1, delay: 0.3, scale: 1});
        // this.cursorNoise();
        // this.cursorScale(15,() => {},0.5);
        this.toggleCursor(el,false);
    }

    // onCompleteFn is fired for each point of segment!!!
    cursorScale = (size,onCompleteFn = () => {},duration = 0.3,bind = {}) => {
        if(isMobile)
            return 

        for(const { point } of this.polygon.segments) {

            gsap.killTweensOf(point);

            gsap.to(point,{
                duration: duration,
                // y: 30 * Math.sin(point.angle*Math.PI/180), 
                // x: 30 * Math.cos(point.angle*Math.PI/180), 
                y: size * Math.sin(Math.radians(point.angle)), 
                x: size * Math.cos(Math.radians(point.angle)), 
                onUpdate: () => {
                    this.polygon.smooth();
                },
                onComplete: onCompleteFn.bind(this,{ point, size, ...bind })
            });

        }

    }

    noising = ( { point, size }) => {
        gsap.to(point,{
            duration: 0.8, 
            y: point.y + size/10 * Math.sin(Math.radians(point.angle)), 
            x: point.x + size/10 * Math.cos(Math.radians(point.angle)), 
            repeat: -1, 
            yoyo: true, 
            delay: Math.random(), 
            ease: "sine.inOut", onUpdate: () => {
                this.polygon.smooth();
            } 
        });
    }

    // to refactor with two method to start and stop noisy
    // cursorNoise = () => {
    //     if(isMobile)
    //         return 

    //     for(const { point } of this.polygon.segments) {

    //         if(this.isNoisy) {

    //             gsap.killTweensOf(point);

    //             gsap.to(point,{
    //                 duration: 0.3,
    //                 // y: 30 * Math.sin(point.angle*Math.PI/180), 
    //                 // x: 30 * Math.cos(point.angle*Math.PI/180), 
    //                 y: 30 * Math.sin(Math.radians(point.angle)), 
    //                 x: 30 * Math.cos(Math.radians(point.angle)), 
    //                 onUpdate: () => {
    //                     this.polygon.smooth();
    //                 },
    //                 onComplete: () => {
    //                     gsap.to(point,{
    //                         duration: 0.8, 
    //                         y: point.y + 3 * Math.sin(Math.radians(point.angle)), 
    //                         x: point.x + 3 * Math.cos(Math.radians(point.angle)), 
    //                         repeat: -1, 
    //                         yoyo: true, 
    //                         delay: Math.random(), 
    //                         ease: "sine.inOut", onUpdate: () => {
    //                             this.polygon.smooth();
    //                         } 
    //                     });
    //                 }
    //             });

    //         } else {

    //             gsap.killTweensOf(point);

    //             gsap.to(point,{
    //                 duration: 0.5, 
    //                 y: 15 * Math.sin(Math.radians(point.angle)), 
    //                 x: 15 * Math.cos(Math.radians(point.angle)), 
    //                 ease: "sine.inOut", onUpdate: () => {
    //                     this.polygon.smooth();
    //                 } });
    //         }            

    //     }
    // }

    render = () => {
        // console.log('render')
        if(this.cursorInner && this.cursorContent && this.group && this.group.position ) {

            gsap.set(this.cursorInner, {x: this.clientX, y: this.clientY});
            gsap.set(this.cursorContent, {x: this.clientX, y: this.clientY});
            // gsap.set(this.cursorContent,{x: this.group.position.x, y: this.group.position.y, ease: "expo.out"});
            // console.log(this.polygon.position.x,this.polygon.position.y);
            if( this.group.position.x > 0.01 || this.group.position.y > 0.01 )
                gsap.to(this.group.position,{duration: 0.6, x: this.clientX, y: this.clientY, ease: "expo.out"});
            else
                this.group.position.x = this.clientX;
                this.group.position.y = this.clientY;
            

            if(window.App?.MainMenu?.isOpen) {
                gsap.to('.li-custom-walker img',{duration: 2.5, x: this.clientX, y: this.clientY, ease: "expo.out"});
            } 
            
        }

        window.requestAnimationFrame(this.render);
        
    }

    _beforeUnload = () => {
        // remove the loop before user reload or unload the page
        window.cancelAnimationFrame(this.render);
        return;
    }


}   