// import "tailwindcss/tailwind.css";
import '../styles/index.scss';
import { gsap, ScrollTrigger } from 'gsap/all';
const axios = require('axios').default;
import { isMobile } from 'mobile-device-detect';
// import Scrollbar from 'smooth-scrollbar';

import Router from './router';
import Mixer from './mixer';
import MainMenu from './modules/main-menu';
import LangMenu from './modules/lang-menu';
import Mouse from './modules/mouse';
import Wine from './modules/wine';
// import * as Animate from './animate';
import Page from './modules/page';


// don't forget to register plugins
gsap.registerPlugin(ScrollTrigger);

//extend Math obj
Math.radians = (deg) => {
	return deg * Math.PI/180;
};

Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
  };

// export const initScrollbar = () => {
// 	// INIT SCROLLBAR
// 	window.App.scroller = document.getElementById('page');

// 	window.App.pageScrollbar = Scrollbar.init(window.App.scroller, {
// 		damping: 0.2, 
// 		delegateTo: document, 
// 		alwaysShowTracks: true
// 	});


// 	// Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element: 
// 	ScrollTrigger.scrollerProxy(window.App.scroller, {
// 		scrollTop(value) {
// 			if (arguments.length) {
// 				window.App.pageScrollbar.scrollTop = value; // setter
// 			}
// 			return window.App.pageScrollbar.scrollTop;    // getter
// 		},
// 		getBoundingClientRect() {
// 		return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
// 		}
// 	});
  
// 	// when the smooth scroller updates, tell ScrollTrigger to update() too: 
// 	window.App.pageScrollbar.addListener(ScrollTrigger.update);

// 	ScrollTrigger.defaults({ scroller: window.App.scroller });
// }

function setVhCustomProp() {
	// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
	let vh = window.innerHeight * 0.01;
	// Then we set the value in the --vh custom property to the root of the document
	// console.log(document.documentElement.style['--vh']);
	// console.log('--vh',vh);
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}


document.addEventListener('DOMContentLoaded', (event) => {


	isMobile && setVhCustomProp();

	// console.log('webpack starterkit');
	// console.log('dom content loaded');

	window.App = {
		Router,
		Mixer,
		'MainMenu': new MainMenu(),
		'Mouse': new Mouse(),
		'wines': [],
		'Page' : new Page(),
		'LangMenu' : new LangMenu(),
	};


	window.App.Page.pageInAnimation().then( () => {

		//init internal link to simulate spa navigation
		Router.initLinks(); // todo move to page class ?

	});


	// INIT SCROLLBAR
	// Animate.initScrollbar();
	// Animate.initImage();
	// Animate.initText();
	// Animate.initRunner();

	// Mixer.init('audio'); //todo move to page ?

	//reload previus page onpopstate
	window.onpopstate = function (e) {
		window.App.Page.closeOverlay();
		Router.changeRoute(window.location.href);
		
	};

	// const audio = new audioManager('audio');
	// let wine = document.getElementById('wine-page')
	// if(wine) {

	// 	window.App.wines[wine.dataset.id] = new Wine(wine.dataset.id);
		
	// }

});

