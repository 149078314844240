import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);

class Mixer {

    // const audible = document.getElementById(id);

    // const play = function() {
    //     console.log(this);
    //     // this.audible.play();
    // }

    shapeIndex = 0;
    startTime = 1;
    audio = null;

    constructor() {
        // console.log('Hello mixer');
    }


    init(id) {
        this.audio = document.getElementById(id);

        if(!this.audio)
            return;

        this.audio.removeAttribute('controls');
        this.audio.setAttribute('volume',0);
        this.audio.currentTime = this.startTime;


        this.initControls();
    }

    initControls() {
        this.toggleBtn = document.getElementById('toggle-sound');
        this.soundIcons = document.querySelectorAll('#toggle-sound .audio-sound-icon');
        this.waves = document.querySelectorAll('#toggle-sound .audio-sound-icon path');
        
        
        this.mutePath = document.getElementById('mute-path');
        this.wavePath = document.getElementById('wave-path');
        
        // this.muteRect = MorphSVGPlugin.convertToPath( document.getElementById('mute-rect') );

        this.soundTl = gsap.timeline({repeat: -1}).timeScale(0).pause();
        this.soundTl.to(this.soundIcons,{duration: 6, xPercent: -100, ease: 'linear'});
        
        this.toggleBtn.addEventListener('click',this.toggle);
        this.pause();
        
    }

    controlsIn = (duration = 1.5) => {
        this.isOnStartTime() ? this.play() : null; 
        gsap.fromTo(this.toggleBtn,{opacity: 0, y: 50},{duration: duration, opacity: 1, y: 0, visibility: 'visible', ease: "power1.out"});
    }

    controlsOut = (duration = 0.8) => {
        gsap.to(this.toggleBtn,{duration: duration, y: 50, opacity: 0, ease: "power1.in", onComplete: () => {
            gsap.set(this.soundIcons,{xPercent: 0});
            gsap.set(this.toggleBtn,{visibility: 'hidden'});
        }});

        this.stop();
    }

    play(duration = 2.5) {
        // console.log('play');
        // let duration = this.audio.currentTime - this.startTime < 3 ? 4 : 2;
        if(!this.audio)
            return;

        this.audio.play();
        gsap.to(this.audio,{duration: duration, volume: 1});
        this.soundTl.play();
        gsap.to(this.soundTl,{duration: duration, timeScale: 1});

        gsap.to(this.waves, {duration: duration/2, morphSVG: {
            shape: this.wavePath,
            shapeIndex: 12,
            // type: "rotational",
            //origin: "50% 50%"
        }});

        // console.log(this.shapeIndex);
        
        
    }

    pause(duration = 1) {

        return new Promise((resolve,reject) => {
            
            try {

                if(!this.audio) {
                    resolve();
                    return;
                }
            

                gsap.to(this.audio,{duration: duration, volume: 0, onComplete: () => {
                    this.audio.pause();
                    resolve();
                }});

                gsap.to(this.soundTl,{duration: duration, timeScale: 0, onComplete: () => {
                    this.soundTl.pause();
                }});

                gsap.to(this.waves, {duration: duration/2, morphSVG: {
                    shape: this.mutePath,
                    shapeIndex: -22,
                    // type: "rotational",
                    //origin: "50% 50%"
                }});

                // this.shapeIndex--;
                
            } catch (error) {
                reject(error);
            }
        });
        
    }

    stop(duration = 1) {
        return new Promise((resolve,reject) => {
            
            try {
                if(!this.audio) {
                    resolve();
                    return;
                }

                this.pause(duration).then(() => {
                    this.audio.currentTime = this.startTime;
                    resolve();
                });
                
            } catch (error) {
                reject(error);
            }
        });
        
    }

    replay() {

        if(!this.audio)
            return;

        if(this.audio.paused) {
            this.audio.currentTime = this.startTime;
            this.play();
        } else {
            this.stop().then(() => {
                this.play();
            });
        }

        
    }

    paused() {
        return this.audio.paused;
    }

    isOnStartTime() {
        return this.audio.currentTime == this.startTime;
    }

    toggle = (e) => {
        e.stopPropagation();
        this.paused() ? this.play() : this.pause();
    }


}

export default new Mixer();