import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Scrollbar from 'smooth-scrollbar';
import { isMobile } from 'mobile-device-detect';

gsap.registerPlugin(ScrollTrigger);


// inizializza le animazioni sui testi allos croll
export const initText = () => {
    let elements = document.querySelectorAll('.entry-content h2:not(.runner), .entry-content h3:not(.runner), .entry-content ul li, .entry-content p:not(.runner)');

    let start = isMobile ? 'top bottom' : 'bottom bottom';

    elements.forEach( el => {

        gsap.fromTo(el,{y: 150, opacity: 0},{duration: 2, y: 0, opacity: 1, ease: 'power1', scrollTrigger: {
            trigger: el,
            start: start,
            toggleActions: 'play none none reverse',
            // onEnter: () => {
            //     console.log('on enter: ',img);
            // }
        }});

    });
};


// inizializza le animazioni sulle immagini allo scroll
export const initImage = () => {

    // ScrollTrigger.defaults({ scroller: window.App.scroller });

    let figures = document.querySelectorAll('.wp-block-image');
    // console.log('image animation:',images);

    figures.forEach(fig => {

        let img = fig.children[0];

        // fix object-fit problem on mobile
        img.remove();
        let newImage = img.cloneNode();
        fig.appendChild(newImage);

        // set scrollTrigger start
        let start = isMobile ? 'top bottom' : 'top center';

        gsap.to(newImage,{duration: 4, scale: 1.2, ease: 'power2.out', delay: 0.5, scrollTrigger: {
            trigger: fig,
            start: start,
            toggleActions: 'play none none reverse',
            // onEnter: () => {
            //     console.log('on enter: ',img);
            // }
        }});
        
        gsap.to(fig,{duration: 4, "clip-path": 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', ease: 'power2.out', delay: 0.5, scrollTrigger: {
            trigger: fig,
            start: start,
            toggleActions: 'play none none reverse',
            // onEnter: () => {
            //     console.log('on enter: ',img);
            // }
        }});

    });
};

export const initHomeImage = () => {

    if(isMobile) {

        let elements = document.querySelectorAll('.wine-container');

        elements.forEach( el => {

            gsap.fromTo(el,{y: 50, opacity: 0},{duration: 1, y: 0, opacity: 1, delay: 1, visibility: 'visible',scrollTrigger: {
                trigger: el,
                start: 'top bottom',
                toggleActions: 'play none none reverse',
                // onEnter: () => {
                //     console.log('on enter: ',img);
                // }
            }});

        });

        
        return;
    }
        
    let header = document.querySelector('#front-page .home-header');
    let tl = gsap.timeline({repeat: -1,yoyo: true}).pause();

    

    let top = document.querySelector('.wine-container.top');
    let right = document.querySelector('.wine-container.right');
    let bottom = document.querySelector('.wine-container.bottom');
    let left = document.querySelector('.wine-container.left');

    let elements = [];


    if(top) {
        elements.push(top);
        tl.fromTo(top,{x: '+=75'},{x: '-=150', duration: 6, ease: 'sine.inOut'},0);
    }

    if(right) {
        elements.push(right);
        tl.fromTo(bottom,{x: '-=65'},{x: '+=110', duration: 6, ease: 'sine.inOut'},0);
    }

    if(bottom) {
        elements.push(bottom);
        tl.fromTo(right,{y: '-=40'},{y: '+=80', duration: 6, ease: 'sine.inOut'},0);
    }

    if(left) {
        elements.push(left);
        tl.fromTo(left,{y: '+=50'},{y: '-=100', duration: 6, ease: 'sine.inOut'},0);
    }

    // elements.push(header);

    createAnimation( top, elements , header,tl );
    createAnimation( right, elements, header ,tl );
    createAnimation( bottom, elements , header,tl );
    createAnimation( left, elements, header ,tl );

    gsap.to([...elements],{duration: 1, opacity: 1, visibility: 'visible'});
    tl.play();


};

function createAnimation(el,elements = [], header,tl) {

    if(!el)
        return;

    let siblings = [...elements];
    siblings.splice(siblings.indexOf(el),1);

    let siblingsTl = gsap.timeline().pause();
    siblingsTl.fromTo([...siblings,header],{opacity: 1},{duration: 1, opacity: 0.1},0);
    

    let elTl = gsap.timeline({ onReverseComplete: () => {
        // gsap.to([...siblings,header],{duration: 1, opacity: 1});
        // // tl.time(0);
        gsap.to(tl,{duration: 0.2, timeScale: 1, onComplete: () => {
            el.addEventListener('mousemove',hoverFn);
            gsap.set(siblings,{pointerEvents: 'all'});
        }});

    }}).pause();

    elTl.to( el ,{duration: 0.6, x: 0, y: 0, zIndex: 100, ease: 'sine.inOut'},0)
    // elTl.to( el ,{duration: 2, left: 130, top: 130, x: 0, y: 0, zIndex: 100, ease: 'sine.inOut'},0)
        .to([el],{duration: 0.6, scale: 1.5, ease: 'sine.inOut'},0)
        // .to([el,...el.children[0].children],{duration: 2, width: 'calc(100vw - 260px)', height: 'calc(100vh - 260px)',ease: 'sine.inOut'},0)
        .to([el.children[0].children[1]],{duration: 0.8, opacity: 1,  ease: 'sine.inOut'},0);
        

    let hoverFn = (e) => {

        // console.log(e,el.clientWidth);

        // TODO AREA ELLITTICA!!!
        let x = el.clientWidth/2 - e.offsetX ;
        let y = el.clientHeight/2 - e.offsetY ;
        let a = Math.max(el.clientWidth/2,el.clientHeight/2);
        let b = Math.min(el.clientWidth/2,el.clientHeight/2);

        if(  (Math.pow( (x*b), 2 ) + Math.pow( (y*a), 2 )) > Math.pow( (a*b), 2 )  ) { //se il punto è esterno all'ellisso inscritto nella foto
            return;
        }

        // AREA CIRCOLARE 120%
        // let x = el.clientWidth/2 - e.offsetX ;
        // let y = el.clientHeight/2 - e.offsetY ;
        // let scale = 1.2;
        // let r = Math.min(el.clientWidth/2,el.clientHeight/2);

        // if( Math.pow(x, 2) + Math.pow(y, 2) > Math.pow( r*scale , 2)) {
            
        //     return;
        // }

        // console.log(r,el.clientWidth,el.clientHeight,x,y);


        // AREA QUADRATA 70%
        // let xHalf = el.clientWidth/2;
        // let yHalf = el.clientHeight/2;
        // let scale = 0.7;

        // if( ( Math.abs( xHalf - e.offsetX ) > xHalf * scale ) || ( Math.abs( yHalf - e.offsetY ) > yHalf * scale )) {
        //     console.log('out of range');
        //     return;
        // }

        // console.log('move');
        // console.log('topHoverFn');
        el.removeEventListener('mousemove',hoverFn);

        gsap.set(siblings,{pointerEvents: 'none'});
        siblingsTl.play();
        
        gsap.to(tl,{duration: 0.1, timeScale: 0, onComplete: () => {

            // console.log('timeline start');
            elTl.invalidate().restart();

            el.addEventListener('mouseleave',mouseLeaveFn);

        }});

        
    };

    let mouseLeaveFn = () => {

        // console.log('leave');
        el.removeEventListener('mouseleave',mouseLeaveFn);
        elTl.pause().reverse();
        siblingsTl.pause().reverse();
    };

    el.addEventListener('mousemove',hoverFn);

}


// inizializza le animazioni dei testi runner
export const initRunner = () => {
    let runners = document.querySelectorAll('.runner');

    runners.forEach(runner => {

        // console.dir(runner);
        let text = runner.innerHTML;
        let splitText = text.split(' ');

        // todo refoctoring this code

        let length = runner.offsetWidth;
        runner.innerHTML = '';
        
        let translatedSplitText = [...splitText];

        for(let i = 0; i < Math.floor( splitText.length/2 ); i++) {

            translatedSplitText.unshift(translatedSplitText.pop());

        }

        let translatedText = translatedSplitText.join(' ');

        let span = document.createElement('span');
        span.innerText = text + ' ' + text;
        span.classList += ' block';

        // let span2 = document.createElement('span');
        // span2.innerText = translatedText + ' ' + translatedText;
        // span2.classList += ' block';

        runner.appendChild(span);
        // runner.appendChild(span2);

        gsap.fromTo(runner,{x: 0 },{x: - runner.offsetWidth + length, repeat: -1, duration:  Math.floor( (runner.offsetWidth/2.0)/ ( text.length*2 + 1) ), ease: 'linear', scrollTrigger: {
            trigger: runner,
            start: 'top bottom',
            end: 'bottom top',
            toggleActions: 'play pause resume pause',
            repeat: -1
            // markers: true,
        }});

    });

};


// inizializza lo smothscroll e lo scrolltrigger
export const initScrollbar = () => {
	// INIT SCROLLBAR
	window.App.scroller = document.getElementById('page');

	window.App.pageScrollbar = Scrollbar.init(window.App.scroller, {
		damping: 0.05, 
		delegateTo: document, 
		alwaysShowTracks: true
	});


	// Tell ScrollTrigger to use these proxy getter/setter methods for the "body" element: 
	ScrollTrigger.scrollerProxy(window.App.scroller, {
		scrollTop(value) {
			if (arguments.length) {
				window.App.pageScrollbar.scrollTop = value; // setter
			}
			return window.App.pageScrollbar.scrollTop;    // getter
		},
		getBoundingClientRect() {
		return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
		}
	});
  
	// when the smooth scroller updates, tell ScrollTrigger to update() too: 
	window.App.pageScrollbar.addListener(ScrollTrigger.update);

	ScrollTrigger.defaults({ scroller: window.App.scroller });
};