import { gsap } from "gsap";
import { isMobile } from 'mobile-device-detect';

/* -----------------------------
    MAIN MENU CLASS
------------------------------*/

export default class MainMenu {

    isOpen = false;

    constructor() {
        this.init();
    }

    init() {
        this.trigger = document.getElementsByClassName('trg-menu');

        for(const tr of this.trigger) {
            tr.addEventListener('click',this.toggle.bind(this,tr));
        }

        // this.menuIcon = document.getElementById('menu-icon');
        // this.menuIconHover = document.getElementById('menu-icon-hover');
    }


    open() {
        return new Promise((resolve,reject) => {

            if(this.isOpen) return resolve();

            try {
                this.isOpen = !this.isOpen;
                gsap.set('.nav-link__bottom-line',{width: 0});
                gsap.set('.li-custom-walker a',{y: 100, opacity: 0});

                gsap.fromTo('.menu-fixed',{opacity: 0},{duration: 1.3, opacity: 1, visibility: 'inherit'});
                gsap.fromTo('.bg-fader',{opacity: 0},{opacity: 0.7, duration: 1.3, visibility: 'inherit', ease: "power3.in"});

                gsap.fromTo('.bg-fixed',{scaleX: 1, scaleY: 0, opacity: 1},{scaleY: 1, scaleX: 1, opacity: 1, visibility: 'inherit', duration: 1.3, ease: "power3.in"});
                gsap.to('.li-custom-walker a',{duration: 1.3, y: 0, opacity: 1, stagger: 0.3, delay: 1, ease: "power2.out", onComplete: () => {
                    isMobile ? resolve() : null;
                }});

                if( !isMobile ) {

                    gsap.to('.nav-link__bottom-line',
                    {
                        duration: 0.8, 
                        width: '100%', 
                        stagger: 0.3, 
                        delay: 1, 
                        ease: "power3.in", 
                        onComplete: () => {
                            resolve();
                        }
                    });

                }
                

            } catch (error) {
                reject();
            }
        });
        
    }

    close() {

        return new Promise((resolve,reject) => {

            if(!this.isOpen) return resolve();

            try {
                this.isOpen = !this.isOpen;

                document.querySelectorAll('.trg-menu.animate').forEach(el => {
                    el.classList.remove('animate');
                })

                gsap.to('.menu-fixed, .bg-fader , .bg-fixed',{opacity: 0, duration: 1, onComplete: () => {
                    gsap.set('.bg-fader , .bg-fixed, .menu-fixed',{visibility: 'hidden',});
                    resolve();
                }});
            } catch (error) {
                reject();
            }
        });
        
    }

    toggle = (tr) => {
        tr.classList.toggle('animate');
        this.isOpen ? this.close() : this.open();

        
        
    }

}

// export const menu = new MainMenu();