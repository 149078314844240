import { gsap } from "gsap";

/* -----------------------------
    LANGUAGES MENU CLASS
------------------------------*/

export default class LangMenu {

    isOpen = false;
    languages = [];

    constructor() {
        this.init();
    }

    init() {

        this.triggers = document.getElementsByClassName('trg-menu__lang');
        this.selector = document.getElementById('languages-selector');

        for(const tr of this.triggers) {
            tr.addEventListener('mouseenter',() => {
                this.clearPostpone();
                this.open();
            });
            tr.addEventListener('mouseleave',this.postponeClose);
        }

        this.selector.addEventListener('mouseenter',() => {
            this.clearPostpone();
        });

        this.selector.addEventListener('mouseleave',this.postponeClose);

    }

    // setLanguages(languages) {
    //     if( typeof languages == 'object' && languages.isArray() )
    //         this.languages = languages;
    // }

    setLangSelector = (selectors) => {

        this.langSelector = document.getElementById('languages-selector');

        if(!this.langSelector)
            return;
        
        this.langSelector.children[0].remove();

        if(selectors instanceof Node) {
            this.langSelector.appendChild(selectors);
        }

    }

    clearPostpone = () => {
        // console.log('postpone clear');
        if(this.postpone)
            clearTimeout(this.postpone);
    }

    postponeClose = () => {
        // console.log('postpone start');
        this.postpone = setTimeout(this.close,1500);
    }

    open = () => {
        if(this.isOpen) 
            return;

        this.isOpen = !this.isOpen;
        gsap.fromTo(this.selector,{xPercent: -80, opacity: 0},{duration: 1, opacity: 1, visibility: 'visible', xPercent: -100, ease: 'expo.out'});
    }

    close = () => {
        this.isOpen = !this.isOpen;

        gsap.to(this.selector,{duration: 0.4, opacity: 0, xPercent: -80, ease: 'expo.in', onComplete: () => {
            gsap.set(this.selector,{visibility: 'hidden'});
        }});
    }

    toggle = () => {
        this.isOpen ? this.close() : this.open();
        
    }
}