import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from 'mobile-device-detect';

gsap.registerPlugin(ScrollTrigger);

import * as Animate from '../animate';
import Wine from './wine';
import Gallery from './gallery';

export default class Page {


    constructor() {
        // this.id = id;

        // console.log(document.getElementsByTagName('main'));
        // this.main = document.getElementsByTagName('main')[0];
        // this.object = this.main.dataset && this.main.dataset.object ? this.main.dataset.object : 'page';
        // this.id = document.getElementById('page').dataset?.id || null;
        this.init();
        // imposta il typo di pagina
        // imposta url pagina

        // console.log(this.object,this.id);

    }

    init = () => {

        return new Promise((resolve,reject) => {

            try {

                this.main = document.getElementsByTagName('main')[0];
                this.object = this.main.dataset && this.main.dataset.object ? this.main.dataset.object : 'page';
                this.id = document.getElementById('page').dataset?.id || null;
                this.overlay = document.getElementById('overlay');

                resolve();
                
            } catch (error) {
                reject(error);
            }

        });
        

    }

    mount = (HTMLContent) => {

        return new Promise((resolve,reject) => {
            try {

                // TODO
                // check if page content is a valid html page
                let page = new DOMParser().parseFromString(
                    HTMLContent,
                    'text/html'
                );

                this.unmount().then(() => {

                    // UPDATE HEAD
                    let newHead = page.head;
                    newHead.children.forEach((el) => {
                        if (
                            el.nodeName !== 'LINK' ||
                            (el.nodeName === 'LINK' && el.getAttribute('id') === undefined)
                        ) {
                            // console.log(el);
                            document.head.prepend(el);
                        }
                    });

                    let newLangSelector = page.getElementById('languages-selector');
                    if(newLangSelector) {

                        window.App.LangMenu.setLangSelector(newLangSelector.children[0]);

                    }

                    // UPDATE BODY
                    // add new #page element
                    document.body.appendChild(page.getElementById('page'));

                    // REINIT PAGE CLASS
                    this.init().then(() => {

                        // init links on page fro spa navigation
                        this.pageInAnimation();
                        window.App.Router.initLinks();

                        resolve();
                    });


                });

                
            } catch (error) {
                reject(error);
            }
        });

        // metodo che monterà la pagina e lancerà le animazione in ingresso a seconta del tipo di pagina

    } 

    // updateLangSelector = (selectors) => {

    //     this.langSelector = document.getElementById('languages-selector');

    //     if(!this.langSelector)
    //         return;
        
    //     this.langSelector.children[0].remove();

    //     if(selectors instanceof Node) {
    //         this.langSelector.appendChild(selectors);
    //     }

    // }

    unmount = () => {
        // metodo che smonterà la pagina se montata e lanciando le animazioni in uscita
        return new Promise((resolve, reject) => {
			try {
				// START EXIT ANIMATION

                window.App.Mixer.controlsOut(); 

                this.pageOutAnimation().then(() => {


                    // .then remove page content                  
                    let oldPage = document.getElementById('page');
                    oldPage.remove();
                    oldPage = null; //cancella dalla memoria?? capire se cé un modo più corretto

                    // reset cursor shape on desktop device
                    if(!isMobile)
                        window.App.Mouse.resetCursor();

                    // and remove old head tags
                    document.head.children.forEach((el) => {
                        if (
                            el.nodeName !== 'LINK' ||
                            (el.nodeName === 'LINK' && el.getAttribute('id') === undefined)
                        ) {
                            // console.log(el);
                            el.remove();
                        }
                    });

                    resolve();
                });

                
			} catch (error) {
				reject(error);
			}
		});
    }

    initOverlay = () => {

        // console.log('init overlay');
        let content = document.querySelector('#page #overlay-content');
        
        if(!content || !this.overlay) {
            return;
        }

        // remove content from dom
        content.remove();
        // console.log(content,'remove content');

        // append content in the overlay
        this.overlay.appendChild(content);
        // console.log(this.overlay,'append content');
        
        // change, add enet listener to close button
        document.querySelector('#overlay #overlay-close-action').addEventListener('click',this.closeOverlay);

    }

    clearOverlay = () => {

        // console.log('clearOverlay');
        let content = document.querySelector('#overlay #overlay-content');
        
        if(!content || !this.overlay) {
            return;
        }

        content.remove();
        // console.log(content,'remove content');

        document.querySelector('#page').appendChild(content);
        // console.log('append content');

    }

    closeOverlay = () => {

        return new Promise((resolve,reject) => {

            try {

                gsap.to(this.overlay,{duration: 1, opacity: 0, onComplete: () => {
                    gsap.set(this.overlay,{visibility: 'hidden'});
                    resolve();
                } });
                
            } catch (error) {
                reject(error);
            }

        });

        
    };

    openOverlay = () => {
        gsap.to(this.overlay,{duration: 1, opacity: 1, visibility: 'visible'});
    }

    pageInAnimation = () => {
        return new Promise((resolve) => {

            Animate.initScrollbar();

            // put form in download-overlay

            // add eventlistener for close action
            this.initOverlay();
            

            switch(this.object) {
                case 'wine':

                    this.initWinePage();

                    this.winePageIn().then( () => {
                        resolve();
                    });
                    break;
                case 'front-page':
                    window.App.Mixer.init('audio');
                    this.frontPageIn().then( () => {

                        this.initFrontPage();
                        
                        resolve();
                    });
                    break;
                default: 

                    this.initDefaultPage();
                    this.defaultPageIn().then( () => {
                        // window.App.Mixer.init('audio');
                        
                        resolve();
                    });
            }

            // if(main.dataset && main.dataset.object == 'wine') {

            //     let id = main.dataset.id;

            //     if(window.App.wines[id]) {

            //         window.App.wines[id].init().then(() => {
            //             return this.fadeIn();
            //         }).then(() => {
            //             resolve();
            //         });

            //     } else {
            //         window.App.wines[id] = new Wine(id);
            //     }                

            // }
            
            // this.fadeIn().then(() => {
            //     resolve();
            // });
            

        });
    }

    initWinePage = () => {

        // inizializzo l'audio
        window.App.Mixer.init('audio-wine');
        // inizializzo le animazioni delle immagini
        Animate.initImage();
        // inizializzo le animazionie dei testi
        Animate.initText();
        // inizializzo le animazioni dei testi runner
        Animate.initRunner();

        // init download overlay
        // add eventlistener on download button
        document.getElementById('download-wine-action').addEventListener('click',this.openOverlay);

        // put form into overlay outside #page

        // inizializzo la gallery
        this.gallery = new Gallery();

    }

    initFrontPage = () => {

        // animiamo i wine-container
        Animate.initHomeImage();
        // 


    }

    initDefaultPage = () => {
        // inizializzo le animazioni delle immagini
        Animate.initImage();
        // inizializzo le animazionie dei testi
        Animate.initText();

        // init lightbox gallery?
        let hasLightbox = document.getElementById('page').dataset.lightbox;
        // console.log(hasLightbox);
        // inizializzo la gallery
        // this.gallery = new Gallery();
    }

    // default page in animation
    defaultPageIn = () => {
        return new Promise((resolve,reject) => {
            try {
                this.fadeIn().then(() => {
                    resolve();
                });
            } catch (error) {
                reject();
            }
            
        });
    }

    // wine page in animation
    winePageIn = () => {
        return new Promise((resolve,reject) => {

            try {

                // if window.App.wines[this.id] -> init
                let wine = window.App.wines[this.id] = window.App.wines[this.id] || new Wine(this.id);

                // else new Wine(this.id)
                // window.App.wines[this.id] = new Wine(this.id);

                wine.init().then(() => {

                    // console.log('page class, after wine init()');

                    let tl = gsap.timeline();

                    tl
                    .fromTo('#page',{opacity: 0},{duration: 2, opacity: 1, onComplete: () => {
                        resolve();
                    }})
                    .fromTo('.wine-header__title-wrapper, #wine-sidebar',{y: 100},{duration: 2, y: 0, ease: 'power3.out'  },0)
                    .fromTo('#wine-canvas',{y: 150, opacity:0},{duration: 2, opacity: 1, y: 0, ease: 'power3.out'  },0.5);


                });

                // init gallery

                // this.fadeIn().then(() => {
                //     resolve();
                // });

                


                
            } catch (error) {
                reject();
            }

        });
    }

    // front page in animation
    frontPageIn = () => {
        return new Promise((resolve,reject) => {

            try {
                this.fadeIn().then(() => {
                    resolve();
                });
            } catch (error) {
                reject();
            }

        });
    }

    pageOutAnimation() {
        
        return new Promise((resolve) => {

            if(window.App && window.App.MainMenu) {
                // chiudo il menu se è aperto
                window.App.MainMenu.close();
            } 

            this.clearOverlay();


            if(this.object === 'wine') {
                // console.log(window.App.wines[this.id]);
                window.App.wines[this.id].trigger?.disable();
            }
            
            gsap.to('#page',{duration: 1, opacity: 0, onComplete: () => {
                resolve();
            }});

        });

    }

    fadeIn = () => {
        return new Promise((resolve,reject) => {
            try {
                
                let tl = gsap.timeline();

                tl
                .fromTo('#page',{opacity: 0},{duration: 1, opacity: 1, onComplete: () => {
                    resolve();
                }})
                .fromTo('header h1, header h2',{y: 100, opacity:0},{duration: 2, opacity: 1, y: 0, stagger: 0.5, ease: 'power3.out'  });
                
            } catch (error) {
                reject(error);
            }
        });
    }



}