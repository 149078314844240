import { gsap } from "gsap";
import { isMobile } from 'mobile-device-detect';

export default class Gallery {


    constructor() {

        this.init();

    }

    init = () => {

        this.index = 0;
        this.galleryItems = [];
        this.items = document.querySelectorAll('.entry-content figure img');
        // console.log(this.items);
        if(this.items?.length)
            this.mount();       

    }

    mount = () => {

        this.gallery = document.createElement('div');
        this.gallery.classList = 'gallery__wrapper';

        for( const i in this.items) {

            let n = parseInt(i);

            let item = this.items[n];

            if(  !(item instanceof Node) || !(item instanceof HTMLImageElement)  ) {
                continue;
            }

            let fig = document.createElement('figure');
            fig.classList = 'gallery__item';
            fig.dataset.index = n;

            // console.log(this.items[i]);

            let img = item.cloneNode();
            // img.classList.add('is-gallery-item');

            fig.appendChild(img);
            this.gallery.appendChild(fig);
            this.galleryItems[n] = fig;

            item.addEventListener('click',this.show.bind(this,n) );
            item.classList.add('gallery-trigger');
            isMobile || fig.addEventListener('click',this.hide);

            window.App.Mouse.addLinks(fig);
            window.App.Mouse.addLinks(item);

        }

        // console.log(this.galleryItems);
        // console.log(this.items);

        // gsap.set(this.galleryItems[this.index],{visibility: 'visible',opacity: 1});
        this.initControls();
        this.addGalleryBtn();

        document.getElementById('page').appendChild(this.gallery);

    }

    initControls = () => {
        this.controls = document.createElement('div');
        this.controls.classList += 'gallery-controls__wrapper';

        this.nextButton = document.createElement('span');
        this.prevButton = document.createElement('span');

        this.nextButton.classList += 'gallery-controls__button button-next cursor-pointer';
        this.prevButton.classList += 'gallery-controls__button button-prev cursor-pointer';

        this.nextButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="43.256" height="83.684" viewBox="0 0 43.256 83.684">
        <path id="next_icon" data-name="next icon" d="M4707.557,1502.125l-41.135,41.135,41.135,41.135" transform="translate(4708.264 1585.102) rotate(180)" fill="none" stroke="#fff" stroke-width="2"/>
        </svg>`;
        this.prevButton.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="43.256" height="83.684" viewBox="0 0 43.256 83.684">
        <path id="prev_icon" data-name="prev icon" d="M4707.557,1502.125l-41.135,41.135,41.135,41.135" transform="translate(-4665.008 -1501.418)" fill="none" stroke="#fff" stroke-width="2"/>
      </svg>
      `;

        this.nextButton.addEventListener('click',this.goToNext);
        this.prevButton.addEventListener('click',this.goToPrevious);

        this.controls.appendChild(this.prevButton);
        this.controls.appendChild(this.nextButton);

        gsap.set(this.controls,{pointerEvents: 'none'});
        gsap.set(this.nextButton,{ opacity: 0, visibility: 'hidden'});
        gsap.set(this.prevButton,{ opacity: 0, visibility: 'hidden'});

        // if mobile addClose button

        this.gallery.appendChild(this.controls);

        window.App.Mouse.addLinks(this.nextButton,this.prevButton);


        if(isMobile) {

            this.hideBtn = document.createElement('button');
            this.hideBtn.classList += 'gallery-close absolute';
            this.hideBtn.style.opacity = 0;
            this.hideBtn.style.visibility = 'hidden';
            this.hideBtn.innerHTML = `
                <svg id="gallery-close-icon" class="cursor-icon" xmlns="http://www.w3.org/2000/svg" width="19.799" height="19.799" viewBox="0 0 19.799 19.799">
                    <g transform="translate(-1528.954 -336.247)">
                        <line id="g-close-line-1" data-name="Close line 1" y2="26" transform="translate(1547.692 337.308) rotate(45)" fill="none" stroke="currentColor" stroke-width="1"/>
                        <line id="g-close-line-2" data-name="Close line 2" x2="26" transform="translate(1530.015 336.6) rotate(45)" fill="none" stroke="currentColor" stroke-width="1"/>
                    </g>
                </svg>
                `;
            this.gallery.appendChild(this.hideBtn);

            this.hideBtn.addEventListener('click',this.hide);

        }

        
    }

    show = (index,e) => {
        // console.log(index,e,this);


        // set i element as current image gallery
        this.goTo(index);

        this.gallery && gsap.to(this.gallery,{duration: 2, backgroundColor: 'rgba(0,0,0,1)'});
        this.nextButton && gsap.to(this.nextButton,{duration: 1, delay: 1, opacity: 1, visibility: 'visible'});
        this.prevButton && gsap.to(this.prevButton,{duration: 1, delay: 1, opacity: 1, visibility: 'visible'});
        this.hideBtn && gsap.to(this.hideBtn,{duration: 0.5, delay: 2, opacity: 1, visibility: 'visible'});

        gsap.to('.logo-main, .trg-menu__wrapper',{duration: 1, opacity: 0, onComplete: () => {
            gsap.set('.logo-main, .trg-menu__wrapper',{visibility: 'hidden'});
        } });
        
        this.addKeyboardTrigger();

    }

    hide = (e) => {

        e.stopPropagation();

        this.removeKeyboardTrigger();

        // console.log('hide');

        // show top bar icon trigger
        gsap.to('.logo-main, .trg-menu__wrapper',{duration: 1, opacity: 1, visibility: 'visible' });

        // hide next button control
        this.nextButton && gsap.to(this.nextButton,{duration: 0.5, opacity: 0, onComplete: () => {
            gsap.set(this.nextButton,{visibility: 'hidden'});
        }});

        // hide prev button control
        this.prevButton && gsap.to(this.prevButton,{duration: 0.5, opacity: 0, onComplete: () => {
            gsap.set(this.prevButton,{visibility: 'hidden'});
        }});

        // hide hide button control 
        // console.log(this.hideBtn);
        this.hideBtn && gsap.to(this.hideBtn,{duration: 0.5, opacity: 0, onComplete: () => {
            gsap.set(this.hideBtn,{visibility: 'hidden'});
        }});

        // hide gallery current item
        let currentItem = this.galleryItems[this.index];
        currentItem && gsap.killTweensOf(currentItem);

        currentItem && gsap.to(currentItem,{duration: 2,opacity: 0, onComplete: () => {
            gsap.set(currentItem, {visibility: 'hidden'});
            gsap.set(this.gallery,{pointerEvents: 'none'});
        }});

        // fadeout gallery background color
        this.gallery && gsap.to(this.gallery,{duration: 2, backgroundColor: 'rgba(0,0,0,0)'});

    }

    goToNext = (e) => {
        e.stopPropagation();

        // console.log('goToNext');
        let index = this.galleryItems[ this.index + 1 ] ? this.index + 1 : 0;

        this.goTo(index);

    }

    goToPrevious = (e) => {
        e.stopPropagation();
        
        // console.log('goToPrev');
        let index = this.galleryItems[ this.index - 1 ] ?  this.index - 1 : this.galleryItems.length - 1;

        this.goTo(index);
    }

    goTo( index = 0 , duration = 2) {

        // console.log(this.index);
        // console.log('goto: '+ index);

        let tl = gsap.timeline();
        let current = this.galleryItems[this.index];

        if(index == this.index) {

            tl.to(current,{duration: duration,opacity: 1, visibility: 'visible', pointerEvents: 'all'});
            
            return;
        }

        let next = this.galleryItems[index];

        gsap.killTweensOf(current);
        gsap.killTweensOf(next);
        

        gsap.set(current,{zIndex: 0});
        gsap.set(next,{zIndex: 10, visibility: 'visible'});

        

        tl.to(next,{duration: duration, opacity: 1, pointerEvents: 'none'},0)
        .to(current,{duration: duration, opacity: 0, onComplete: () => {
            gsap.set(current,{visibility: 'hidden'});
            gsap.set(next,{pointerEvents: 'all'});
        }},0);

        this.index = index;

    }

    addKeyboardTrigger = () => {

        document.addEventListener("keydown", this.keyboardEventHandler);

    }

    removeKeyboardTrigger = () => {

        document.removeEventListener("keydown", this.keyboardEventHandler);

    }

    keyboardEventHandler = (e) => {
        // console.log(e, e.code);
        if (e.isComposing || e.code === 229) {
          return;
        }

        switch(e.code) {

            case 'ArrowLeft':
                this.goToPrevious(e);
                break;
            case 'ArrowRight':
                this.goToNext(e);
                break;
        }
        // do somethings
    }

    addGalleryBtn = () => {

        const page = document.getElementById('page');

        this.galleryBtnSection = document.getElementById('gallery-btn__wrapper').cloneNode();
        this.galleryBtnSection.style.display = 'flex';

        const btn = document.getElementById('open-gallery');

        this.galleryBtn = btn.cloneNode();
        this.galleryBtn.innerHTML = btn.innerHTML;

        this.galleryBtn.addEventListener('click',this.show.bind(this,0));

        this.galleryBtnSection.appendChild(this.galleryBtn);
        page.querySelector('.entry-content').appendChild(this.galleryBtnSection);

        window.App.Mouse.addLinks(this.galleryBtn);

    }

}