import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from 'mobile-device-detect';

gsap.registerPlugin(ScrollTrigger);

export default class Wine {

    progress = 0;
    index = 0;
    canvas = null;
    ctx = null;

    constructor(id) {
        this.id = id;

        // this.init();
        // ScrollTrigger.defaults({ scroller: window.App.scroller });
    }

    init() {

        return new Promise((resolve,reject) => {

            try {

                this.progress = 0;
                this.index = 0;
                this.canvas = document.getElementById('wine-canvas');

                if( !this.canvas ) {
                    resolve();
                }

                this.ctx = this.canvas.getContext('2d');
                

                if(this.gallery?.length) {

                    // console.log('prefetched init');
                    
                    this.drawImage(this.gallery[0]);
    
                    this.start();

                    resolve();
                    
                } else {

                    this.sources = this.canvas.dataset.sources.split(',').sort();

                    this.loadGallery().then(() => {

                        // console.log(this.gallery);
                        // risolvere non è asincrono!!
                        // console.log('await load gallery: ',this.progress,this.gallery[0]);
                        
                        this.loadImg(this.sources[0]).then(img => {
                            // console.log(img);
                            this.drawImage(img);
    
                            this.start();
    
                            resolve();
                        });
    
                        // window.addEventListener('wheel',this.onWheel);
                        // todo if mobile??
                        
    
                    });

                }
        
                
            } catch (error) {
                reject(error);
            }

        });

    }

    static _preload(HTMLContent) {

        // parse ctx

        let page = new DOMParser().parseFromString(
            HTMLContent,
            'text/html'
        );

        let id = page.getElementById('page').dataset?.id || null;

        let canvas = page.getElementById('wine-canvas');

        if( !canvas || !id ) {
            return null;
        }
        

        let wine = new Wine(id);
        wine.sources = canvas.dataset.sources.split(',').sort();
        window.App.wines[id] = wine;
        wine.loadGallery().then(() => {
            return wine;
        });
        
    }

    drawImage = (img) => {
        if(img instanceof HTMLImageElement)
            this.ctx.drawImage(img, 0, 0);
    }

    // loadImg = (src,onloadFn = () => {}) => {
        
    //     let img = new Image();
    //     img.onload = onloadFn.bind(this,img);
    //     img.src = src;
    //     return img;
    // }

    loadImg = (src) => {

        return new Promise((resolve,reject) => {
            let img = new Image();
            img.onload = () => { 
                this.updateProgress();
                // console.log('onload',this.progress);    
                resolve(img); 
            };
            img.onerror = reject;
            img.src = src;
        });
        
    }

    loadGallery = () => {

        this.gallery = [];

        return new Promise((resolve,reject) => {
            try {

                for (const i in this.sources) {
                    
                    // let img = this.loadImg(src,this.updateProgress);
                    // this.gallery.push(img);
                    this.loadImg(this.sources[i]).then( img => {
                        this.gallery[i] = img;
                    });

                }

                resolve();
                
            } catch (error) {
                reject(error);
            }
        });

    }

    start = () => {

        // let tl = gsap.timeline({
        //     ScrollTrigger: {
        //         trigger: '#wine-header',
        //         scrub: true,
        //         start: 'top top',
        //         markers: true,
        //         end: () => '+=500',
        //         onUpdate: (self) => {
        //             // console.log(this.index);
        //             console.log(Math.round(self.progress*this.sources.length));
        //             this.drawImage(this.gallery[Math.round(self.progress*this.sources.length)]);
        //         },
        //         onEnter: () => {
        //             console.log('on enter');
        //         },
        //         onLeave: () => {
        //             console.log('on leave');
        //         }
        //     }
        // });

        // let sidebar = document.getElementById('wine-sidebar');
        // console.log(sidebar.offsetHeight);

        let startIDEl = isMobile ? 'wine-canvas' : 'wine-header';
        let start = isMobile ? ' 70%' : ' top';

        this.trigger = ScrollTrigger.create({
            trigger: '#'+startIDEl,
            scrub: true,
            start: 'top' + start,
            // markers: true,
            end: () => '' + ( document.getElementById(startIDEl).offsetHeight ) + 'px 35%',
            onUpdate: (self) => {
                // console.log(this.index);
                // console.log(Math.round(self.progress*this.sources.length));
                this.drawImage(this.gallery[Math.round(self.progress * ( this.sources.length - 1 ))]);
            },
            onEnterBack: () => {
                // console.log('on enter');
                gsap.to('#'+startIDEl,{duration: 0.6,opacity: 1, ease: 'power1.out'});
                window.App.Mixer.controlsOut();
            },
            onLeave: () => {
                // console.log('on leave');
                // console.log(content.offsetTop);
                // if(!this.onLeaveFired) {
                //     this.onLeaveFired = true;
                    
                // }

                let content = document.getElementById('wine-content');

                if(!isMobile) {

                    // window.App.pageScrollbar.options.delegateTo = null;
                    // window.App.pageScrollbar.update();

                    window.App.pageScrollbar.scrollIntoView(content,{
                        /**
                         * Whether to align to the top or the bottom edge of container.
                         */
                        alignToTop: true,
                      
                        /**
                         * Set to true to prevent scrolling when target element is visible.
                         */
                        onlyScrollIfNeeded: false,
                      
                        /**
                         * Offset to left edge of container.
                         */
                        offsetLeft: 0,
                      
                        /**
                         * Offset to top edge of container (used only if `alignToTop=true`)
                         */
                        offsetTop: 100,
                      
                        /**
                         * Offset to bottom edge of container (used only if `alignToTop=false`).
                         */
                        offsetBottom: 0,
                      });

                    // window.App.pageScrollbar.scrollTo(0,content.offsetTop - 100, 1300,{
                    //     callback: () => { 
                    //         console.log('Done scroll!',window.App.pageScrollbar); 
                    //         // window.App.pageScrollbar.options.delegateTo = document;
                    //         // window.App.pageScrollbar.update(); 
                    //     },
                    //     easing: (number) => number,
                    // });
                }
                    

                window.App.Mixer.controlsIn();

                gsap.to('#'+startIDEl,{duration: 0.3,opacity: 0, ease: 'power1.in'});
                
            }
        });

        // tl.to(this,{
        //     duration: 3,
        //     index: this.gallery.length - 1,
        //     ease: 'none',
        // })
        // .to('#wine-header',{
        //     opacity: 0,
        //     duration: 3,
        // })

        // gsap.to(this,{
        //     scrollTrigger: {
        //         trigger: '#wine-header',
        //         scrub: true,
        //         start: '75% center',
        //         pin: true,
        //         end: () => '+=' + document.getElementById('wine-header').offsetHeight,
        //         // markers: true,
        //         onUpdate: () => {
        //             // console.log(this.index);
        //             this.drawImage(this.gallery[Math.round(this.index)]);
        //         },
        //         onLeave: () => {
        //             gsap.to('#wine-canvas',{duration: 1, opacity: 0, y: -400, ease: 'power2.in'});
        //         },
        //         onEnterBack: () => {
        //             gsap.to('#wine-canvas',{duration: 1, opacity: 1, y: 0, ease: 'power2.out'});
        //         }
        //     },
        //     duration: 3,
        //     index: this.gallery.length - 1,
        //     ease: 'none'
        // })


    }

    // onWheel = (e) => {

    //     let newIndex = ( this.index + Math.round(e.deltaY / (360 / this.gallery.length) ) ).clamp(0, this.gallery.length - 1 );

    //     if(this.index == newIndex) {
    //         return;
    //     }

    //     gsap.to(this,{duration: 1, index: newIndex, ease: "expo.out", onUpdate: () => {
    //         this.drawImage(this.gallery[Math.ceil(this.index)]);
    //     }});

    // }

    updateProgress = () => {
        this.progress++;
        // console.log( Math.round(this.progress * 100 / this.sources.length) + '% loaded' );
        
    }

}